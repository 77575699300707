import React from "react"
import { generateImage } from "../factories/image-factory"
import validateClass from "../helpers/validate-class"
import { VALID_COLUMN_CLASSES } from "../layout/column"
import Chevron from "../unitary/chevron"
import Content from "../unitary/content"
import H2 from "../unitary/h2"

class Landscape extends React.Component {
  render() {
    const titleData = this.props.data.title

    const titleClasses = this.props.titleClasses || []
    validateClass(VALID_COLUMN_CLASSES, titleClasses)

    let titleComponent = null
    if (titleData) {
      let titleInnerComponent = null
      if (this.props.titleStyle === "chevron") {
        titleInnerComponent = (
          <Chevron listId={0}>
            <H2 title={titleData} />
          </Chevron>
        )
      } else {
        titleInnerComponent = <H2 title={titleData} />
      }
      titleComponent = (
        <div
          className={`column has-text-centered is-flex is-align-items-center ${titleClasses.join(
            " "
          )}`}
        >
          {titleInnerComponent}
        </div>
      )
    }

    const bodyData = this.props.data.body

    let bodyComponent = null

    if (bodyData) {
      bodyComponent = (
        <div className="column is-flex is-align-items-center">
          <Content body={this.props.data.body} />
        </div>
      )
    }
    const imageInnerComponent = generateImage(
      this.props.images,
      this.props.data.image
    )

    let imageComponent = null
    if (imageInnerComponent) {
      imageComponent = (
        <div className={'column'}>
          {imageInnerComponent}
        </div>
      )
    }

    if (this.props.imageFirst) {
      return (
        <div className="columns">
          {titleComponent}
          {imageComponent}
          {bodyComponent}
        </div>
      )
    } else {
      return (
        <div className="columns">
          {titleComponent}
          {bodyComponent}
          {imageComponent}
        </div>
      )
    }
  }
}

export default Landscape
