// @ts-ignore
import yaml from "../../data/social-and-influencer-marketing.yaml"
import { graphql } from "gatsby"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import TitleWithUnderline from "../../components/unitary/title-with-underline"
import Landscape from "../../components/h2nits/landscape"
import {
  Portrait,
  PORTRAIT_BODY_TEXT_ALIGNMNENTS,
} from "../../components/child-mappable/portrait"
import Columns from "../../components/layout/columns"
import Header from "../../components/sections/header"
import { SectionBox } from "../../components/sections/section-box"
import TileParentChildBox from "../../components/layout/tile-parent-child-box"
import Content from "../../components/unitary/content"
import { SectionTileAncestor } from "../../components/sections/section-tile-ancestor"
import LayoutContainer from "../../components/layout/layout-container"
import { PortraitArrayWithTitle } from "../../components/sections/portrait-array-with-title"

class SocialAndInfluencerMarketing extends React.Component {
  constructor(props) {
    super()
  }

  render() {
    validatePageYamlData(yaml)
    const images = this.props.data

    return (
      <LayoutContainer>
        <Header {...yaml.header} />
        <PortraitArrayWithTitle
          data={yaml.range_of_strategies}
          images={images}
        />
        <SectionBox>
          <TitleWithUnderline title={yaml.value_creation.title} />
          <Landscape data={yaml.value_creation.children[0]} images={images} />
        </SectionBox>
        <PortraitArrayWithTitle
          data={yaml.collaboration_assets}
          images={images}
        />
        <SectionBox>
          <TitleWithUnderline title={yaml.invest_in_collaboration.title} />
          <Columns>
            <Portrait
              data={yaml.invest_in_collaboration.children[0]}
              options={{
                portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
              }}
            />
            <Portrait
              data={yaml.invest_in_collaboration.children[1]}
              options={{
                portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
              }}
            >
              <Columns>
                <Portrait
                  data={yaml.invest_in_collaboration.children[1].children[0]}
                  images={images}
                />
                <Portrait
                  data={yaml.invest_in_collaboration.children[1].children[1]}
                  images={images}
                  columnModifier="is-narrow"
                />
                <Portrait
                  data={yaml.invest_in_collaboration.children[1].children[2]}
                  images={images}
                />
              </Columns>
            </Portrait>
            <Portrait
              data={yaml.invest_in_collaboration.children[2]}
              images={images}
              options={{
                portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
              }}
            />
          </Columns>
        </SectionBox>
        <SectionTileAncestor>
          <TileParentChildBox>
            <TitleWithUnderline
              title={yaml.applies_to_lifestyle_categories.title}
            />
            <Content body={yaml.applies_to_lifestyle_categories.body} />
          </TileParentChildBox>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.invest_in_structure.title} />
            <Landscape
              data={yaml.invest_in_structure.children[0]}
              titleStyle="chevron"
            />
            <Landscape
              data={yaml.invest_in_structure.children[1]}
              titleStyle="chevron"
            />
          </TileParentChildBox>
        </SectionTileAncestor>
      </LayoutContainer>
    )
  }
}

export default SocialAndInfluencerMarketing

export const query = graphql`
  query {
    strategy_tropicana: file(
      relativePath: {
        eq: "social-and-influencer-marketing/strategy_tropicana.png"
      }
    ) {
      ...fixedWidthFourColumns
    }
    strategy_pantene: file(
      relativePath: {
        eq: "social-and-influencer-marketing/strategy_pantene.png"
      }
    ) {
      ...fixedWidthFourColumns
    }
    strategy_fashion_nova: file(
      relativePath: {
        eq: "social-and-influencer-marketing/strategy_fashion_nova.png"
      }
    ) {
      ...fixedWidthFourColumns
    }
    strategy_huda_beauty: file(
      relativePath: {
        eq: "social-and-influencer-marketing/strategy_huda_beauty.png"
      }
    ) {
      ...fixedWidthFourColumns
    }
    swatch_party: file(
      relativePath: { eq: "social-and-influencer-marketing/swatch_party.png" }
    ) {
      ...unsizedFluidImage
    }
    collaboration_proposition: file(
      relativePath: {
        eq: "social-and-influencer-marketing/collaboration_proposition.png"
      }
    ) {
      ...fixedWidthFiveColumns
    }
    collaboration_venues: file(
      relativePath: {
        eq: "social-and-influencer-marketing/collaboration_venues.png"
      }
    ) {
      ...fixedWidthFiveColumns
    }
    collaboration_owned_media: file(
      relativePath: {
        eq: "social-and-influencer-marketing/collaboration_owned_media.png"
      }
    ) {
      ...fixedWidthFiveColumns
    }
    collaboration_product_development: file(
      relativePath: {
        eq: "social-and-influencer-marketing/collaboration_product_development.png"
      }
    ) {
      ...fixedWidthFiveColumns
    }
    collaboration_events: file(
      relativePath: {
        eq: "social-and-influencer-marketing/collaboration_events.png"
      }
    ) {
      ...fixedWidthFiveColumns
    }
    star_the_influencer: file(
      relativePath: {
        eq: "social-and-influencer-marketing/star_the_influencer.png"
      }
    ) {
      ...fixedWidth90
    }
    star_arrow: file(
      relativePath: { eq: "social-and-influencer-marketing/star_arrow.png" }
    ) {
      ...fixedWidth30
    }
    star_the_brand: file(
      relativePath: { eq: "social-and-influencer-marketing/star_the_brand.png" }
    ) {
      ...fixedWidth90
    }
    colourpop_logo: file(
      relativePath: { eq: "social-and-influencer-marketing/colourpop_logo.png" }
    ) {
      ...fixedWidthThreeColumns
    }
  }
`
